import styled, { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import { theme } from './constants/theme';
import { Link } from 'gatsby';
import {
    FacebookWithCircle,
    LinkedinWithCircle,
} from '@styled-icons/entypo-social';
import 'typeface-work-sans';
import 'typeface-montserrat';

interface GlobalProps {
    theme: typeof theme;
}

interface GlobalStylingProps {
    marginMobile?: string;
    marginDesktop?: string;
    paddingDesktop?: string;
    paddingMobile?: string;
    textAlign?: string;
    color?: string;
}

const GlobalStyle = createGlobalStyle<GlobalProps>`
    ${normalize}

    html {
        height: 100%;
        font-size: 10px;
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size:16px;
        line-height:24px;
    }
    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }
    body {
        padding: 0;
        font-family: ${(props) => props.theme.fontFamily.primary};
    }
    ul{
        padding:0;
        margin:0;
    }
    h1, h2, h3, h4, h5, p{
        margin:0;
    }

    .slick-arrow{
        background-color: ${(props) => props.theme.colors.black2};
        border-radius: 50%;
        width: 32px;
        height: 32px;
        z-index: ${(props) => props.theme.zIndices.overlap};
    }

    .slick-prev{
        left: -16px;
    }

    .slick-next{
        right: -16px;
    }

    .slick-arrow::before{
        font-family: unset;
        opacity: 1;
        position: absolute;
        left: 50%;
        top: 54%;
        transform: translate(-50%, -50%);
    }

    .slick-prev::before{
        content: url(${require('./assets/icons/prev_arrow.svg')});
    }

    .slick-next::before{
        content: url(${require('./assets/icons/next_arrow.svg')});
    }

    .slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus{
        background-color: ${(props) => props.theme.colors.black2};
    }

    .slick-dots li button:before{
        width: 16px;
        height: 16px;
        border-radius: 50%;
        opacity: 1;
        background-color: ${(props) => props.theme.colors.black2};
        content: '';
    }
    .slick-dots li.slick-active button:before{
        opacity:1;
        background-color: ${(props) => props.theme.colors.orange};
    }
    .slick-dots li button:hover, .slick-dots li button:focus{
        opacity:0.8px;
    }

    @media screen and (min-width:${(props) => props.theme.devices.desktop}){
        .slick-arrow{
            width: 56px;
            height: 56px;
        }

        .slick-prev{
            left: 0px;
        }

        .slick-next{
            right: 0px;
        }
    }

    @media screen and (min-width:${(props) => props.theme.devices.bigScreen}){
        .slick-prev{
            left: -66px;
            transform: translateX(-100%);
        }
        .slick-next{
            right: -66px;
            transform: translateX(100%);
        }
    }
`;

const DesktopOnly = styled.div`
    display: none;
    @media screen and (min-width: ${(props) => props.theme.devices.desktop}) {
        display: block;
    }
`;

const MobileOnly = styled.div`
    display: block;
    @media screen and (min-width: ${(props) => props.theme.devices.desktop}) {
        display: none;
    }
`;

const H1 = styled.h1<GlobalStylingProps>`
    font-size: ${(props) => props.theme.fontSizes.header1.mobile};
    line-height: ${(props) => props.theme.lineHeight.header1.mobile};
    color: ${(props) =>
        props.color ? props.color : props.theme.colors.black2};
    ${(props) => props.marginMobile && `margin:${props.marginMobile};`}
    ${(props) => props.paddingMobile && `padding:${props.paddingMobile};`}
    font-weight: ${(props) => props.theme.fontWeights.bold};

    @media screen and (min-width: ${(props) => props.theme.devices.desktop}) {
        font-size: ${(props) => props.theme.fontSizes.header1.desktop};
        line-height: ${(props) => props.theme.lineHeight.header1.desktop};
        ${(props) => props.marginDesktop && `margin:${props.marginDesktop};`}
        ${(props) => props.paddingDesktop && `padding:${props.paddingDesktop};`}
    }
`;

const H2 = styled.h2<GlobalStylingProps>`
    font-size: ${(props) => props.theme.fontSizes.header2.mobile};
    line-height: ${(props) => props.theme.lineHeight.header2.mobile};
    color: ${(props) => props.theme.colors.black2};
    ${(props) => props.marginMobile && `margin:${props.marginMobile};`}
    ${(props) => props.paddingMobile && `padding:${props.paddingMobile};`}
  font-weight: ${(props) => props.theme.fontWeights.bold};

    @media screen and (min-width: ${(props) => props.theme.devices.desktop}) {
        font-size: ${(props) => props.theme.fontSizes.header2.desktop};
        line-height: ${(props) => props.theme.lineHeight.header2.desktop};
        ${(props) => props.marginDesktop && `margin:${props.marginDesktop};`}
        ${(props) => props.paddingDesktop && `padding:${props.paddingDesktop};`}
    }
`;

const H3 = styled.h3<GlobalStylingProps>`
    font-size: ${(props) => props.theme.fontSizes.header3.mobile};
    line-height: ${(props) => props.theme.lineHeight.header3.mobile};
    color: ${(props) => props.theme.colors.black2};
    ${(props) => props.marginMobile && `margin:${props.marginMobile};`}
    ${(props) => props.paddingMobile && `padding:${props.paddingMobile};`}
  font-weight: ${(props) => props.theme.fontWeights.bold};
    ${(props) => props.textAlign && `text-align:${props.textAlign};`}

    @media screen and (min-width: ${(props) => props.theme.devices.desktop}) {
        font-size: ${(props) => props.theme.fontSizes.header3.desktop};
        line-height: ${(props) => props.theme.lineHeight.header3.desktop};
        ${(props) => props.marginDesktop && `margin:${props.marginDesktop};`}
        ${(props) => props.paddingDesktop && `padding:${props.paddingDesktop};`}
    }
`;

const H4 = styled.h4<GlobalStylingProps>`
    font-size: ${(props) => props.theme.fontSizes.header4};
    line-height: ${(props) => props.theme.lineHeight.header4};
    color: ${(props) => props.theme.colors.black2};
    ${(props) => props.marginMobile && `margin:${props.marginMobile};`}
    ${(props) => props.paddingMobile && `padding:${props.paddingMobile};`}
  font-weight: ${(props) => props.theme.fontWeights.bold};

    @media screen and (min-width: ${(props) => props.theme.devices.desktop}) {
        ${(props) => props.marginDesktop && `margin:${props.marginDesktop};`}
        ${(props) => props.paddingDesktop && `padding:${props.paddingDesktop};`}
    }
`;

const Description = styled.p<GlobalStylingProps>`
    font-family: ${(props) => props.theme.fontFamily.secondary};
    ${(props) => props.marginMobile && `margin:${props.marginMobile};`}
    ${(props) => props.paddingMobile && `padding:${props.paddingMobile};`}
    color: ${(props) =>
        props.color ? props.color : props.theme.colors.black2};

    @media screen and (min-width: ${(props) => props.theme.devices.desktop}) {
        ${(props) => props.marginDesktop && `margin:${props.marginDesktop};`}
        ${(props) => props.paddingDesktop && `padding:${props.paddingDesktop};`}
    }
`;

const TextLink = styled(Link)<GlobalStylingProps>`
    color: ${(props) => props.theme.colors.white};
    ${(props) => props.marginMobile && `margin:${props.marginMobile};`}
    ${(props) => props.paddingMobile && `padding:${props.paddingMobile};`}

    @media screen and (min-width: ${(props) => props.theme.devices.desktop}) {
        ${(props) => props.marginDesktop && `margin:${props.marginDesktop};`}
        ${(props) => props.paddingDesktop && `padding:${props.paddingDesktop};`}
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: ${(props) => props.theme.devices.desktop}) {
        flex-direction: row;
    }
`;

const SocialMediaLinks = styled.div`
    display: flex;
`;

const MenuItemFB = styled(FacebookWithCircle)<{ color?: string }>`
    width: 32px;
    height: 32px;
    color: ${(props) => (props.color ? props.color : props.theme.colors.white)};
    margin: 0 8px;
`;

const MenuItemLI = styled(LinkedinWithCircle)<{ color?: string }>`
    width: 32px;
    height: 32px;
    color: ${(props) => (props.color ? props.color : props.theme.colors.white)};
    margin: 0 8px;
`;

export {
    DesktopOnly,
    MobileOnly,
    H1,
    H2,
    H3,
    H4,
    Description,
    TextLink,
    ButtonContainer,
    SocialMediaLinks,
    MenuItemFB,
    MenuItemLI,
};
export default GlobalStyle;
