import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { DownArrowAlt } from '@styled-icons/boxicons-regular/DownArrowAlt';
import { theme } from '../constants/theme';

interface ButtonStylingProps {
    width: string;
    marginMobile?: string;
    marginDesktop?: string;
    type?: string;
    color?: string;
    background?: string;
}

interface ButtonProps {
    title: string;
    width?: string;
    link?: string;
    arrowDown?: boolean;
    marginMobile?: string;
    marginDesktop?: string;
    type?: string;
    color?: string;
    background?: string;
}

const Button: FC<ButtonProps> = ({
    title,
    width = '100%',
    link,
    arrowDown = false,
    marginMobile,
    marginDesktop,
    type,
    color = theme.colors.white,
    background = theme.colors.black2,
}) => {
    return (
        <>
            {type ? (
                <InputElement
                    width={width}
                    marginMobile={marginMobile}
                    marginDesktop={marginDesktop}
                    type={type}
                    value={title}
                />
            ) : link ? (
                <Link to={link}>
                    <ButtonElement
                        width={width}
                        marginMobile={marginMobile}
                        marginDesktop={marginDesktop}
                        color={color}
                        background={background}
                    >
                        {title}
                        {arrowDown && <ArrowDown />}
                    </ButtonElement>
                </Link>
            ) : (
                <ButtonElement
                    width={width}
                    marginMobile={marginMobile}
                    marginDesktop={marginDesktop}
                >
                    {title}
                    {arrowDown && <ArrowDown />}
                </ButtonElement>
            )}
        </>
    );
};

const ButtonStyling = css<ButtonStylingProps>`
    background-color: ${(props) =>
        props.background ? props.background : props.theme.colors.black2};
    color: ${(props) => (props.color ? props.color : props.theme.colors.white)};
    font-size: ${(props) => props.theme.fontSizes.medium};
    line-height: ${(props) => props.theme.lineHeight.large};
    font-weight: ${(props) => props.theme.fontWeights.regularBold};
    ${(props) => props.marginMobile && `margin:${props.marginMobile};`}
    padding: 8px 16px;
    border-radius: 39px;
    width: ${(props) => props.width};
    border: unset;
    cursor: pointer;
    &:focus {
        outline: none;
    }
    @media screen and (min-width: 768px) {
        width: unset;
    }
    @media screen and (min-width: ${(props) => props.theme.devices.desktop}) {
        ${(props) => props.marginDesktop && `margin:${props.marginDesktop};`}
    }
`;

const ButtonElement = styled.button`
    ${ButtonStyling}
`;
const InputElement = styled.input`
    ${ButtonStyling}
`;

const ArrowDown = styled(DownArrowAlt)`
    height: 24px;
    width: 24px;
    margin-left: 12px;
`;

export default Button;
