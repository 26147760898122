import React from 'react';
import { ThemeProvider } from 'styled-components';

const theme = {
    fontFamily: {
        primary: "'Montserrat', sans-serif",
        secondary: "'Work Sans', sans-serif",
    },
    devices: {
        mobile: '576px',
        tablet: '768px',
        laptop: '992px',
        desktop: '1200px',
        bigScreen: '1500px',
    },
    colors: {
        lightBlue: '#E6F1F8',
        darkBlue: '#004472',
        orange: '#FF6C00',
        black: '#000000',
        black2: '#212121',
        lightGrey: '#F0F0F0',
        white: '#FFFFFF',
    },
    fontWeights: {
        light: '100',
        regular: '400',
        regularBold: '500',
        bold: '700',
    },
    zIndices: {
        hide: -1,
        overlap: 1,
        front: 2,
    },
    fontSizes: {
        small: '14px',
        medium: '16px',
        large: '32px',
        header1: {
            desktop: '56px',
            mobile: '32px',
        },
        header2: {
            desktop: '40px',
            mobile: '28px',
        },
        header3: {
            desktop: '32px',
            mobile: '24px',
        },
        header4: '24px',
    },
    lineHeight: {
        small: '16px',
        medium: '24px',
        large: '32px',
        header1: {
            desktop: '64px',
            mobile: '40px',
        },
        header2: {
            desktop: '48px',
            mobile: '32px',
        },
        header3: {
            desktop: '40px',
            mobile: '32px',
        },
        header4: '32px',
    },
    margin: {
        regular: {
            desktop: '16px',
            mobile: '8px',
        },
        regularLarge: {
            desktop: '24px',
            mobile: '12px',
        },
        big: {
            desktop: '32px',
            mobile: '16px',
        },
    },
    padding: {
        regular: {
            desktop: '16px',
            mobile: '8px',
        },
        regularLarge: {
            desktop: '24px',
            mobile: '12px',
        },
        big: {
            desktop: '32px',
            mobile: '16px',
        },
    },
};

const Theme = ({ children }) => (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export { theme };
export default Theme;
