import React, { FC, useState } from 'react';
import styled from 'styled-components';
import Container from './container';
import { Link } from 'gatsby';
import { Menu } from '@styled-icons/boxicons-regular/Menu';
import { Cross } from '@styled-icons/entypo/Cross';
import Scrollspy from 'react-scrollspy';
import {
    DesktopOnly,
    MobileOnly,
    SocialMediaLinks,
    MenuItemFB,
    MenuItemLI,
} from '../globalStyling';

interface MenuNavStylingProp {
    nav: boolean;
}

interface Props {
    menuItems?: boolean;
}

const toggleNavMobile = () => {
    const [nav, showNav] = useState(false);

    const toggleNav = () => showNav((nav) => !nav);
    return { nav, toggleNav };
};

const MenuLinks = () => {
    return (
        <Scrollspy
            items={[
                'intro',
                'diensten',
                'samenwerkingen',
                'projecten',
                'testimonials',
                'contact',
            ]}
            currentClassName="is-current"
        >
            <li>
                <Link to="/#intro">Home</Link>
            </li>
            <li>
                <Link to="/#diensten">Diensten</Link>
            </li>
            <li>
                <Link to="/#samenwerkingen">Opdrachtgevers</Link>
            </li>
            <li>
                <Link to="/#projecten">Projecten</Link>
            </li>
            <li>
                <Link to="/#testimonials">Wat anderen vertellen</Link>
            </li>
            <li>
                <Link to="/#contact">Contact</Link>
            </li>
            <SocialMediaLinks>
                <a
                    className="SM-link"
                    href="https://www.facebook.com/HetSportpunt/"
                >
                    <MenuItemFB />
                </a>
                <a
                    className="SM-link"
                    href="https://www.linkedin.com/in/edwin-korenberg-34b1b617/ "
                >
                    <MenuItemLI />
                </a>
            </SocialMediaLinks>
        </Scrollspy>
    );
};

const Layout: FC<Props> = ({ children, menuItems = true }) => {
    const { toggleNav, nav } = toggleNavMobile();

    if (typeof window !== 'undefined') {
        require('smooth-scroll')('a[href*="#"]');
    }

    return (
        <>
            <Header>
                <Container>
                    <HeaderContainer>
                        <img src={require('../assets/img/logo.png')} />
                        {menuItems && (
                            <>
                                <MobileOnly>
                                    <MobileMenu>
                                        <MenuIcon
                                            onClick={() => toggleNav()}
                                            nav={nav}
                                        />
                                        <MenuIconClose
                                            onClick={() => toggleNav()}
                                            nav={nav}
                                        />
                                    </MobileMenu>
                                </MobileOnly>
                                <DesktopOnly>
                                    <MenuLinksDesktop>
                                        <MenuLinks />
                                    </MenuLinksDesktop>
                                </DesktopOnly>
                            </>
                        )}
                    </HeaderContainer>
                </Container>
                {menuItems && (
                    <MobileOnly>
                        <MenuLinksMobile nav={nav}>
                            <MenuLinks />
                        </MenuLinksMobile>
                    </MobileOnly>
                )}
            </Header>
            {children}
        </>
    );
};

const Header = styled.div`
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: ${(props) => props.theme.colors.black2};
    z-index: ${(props) => props.theme.zIndices.front};
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: ${(props) => props.theme.zIndices.overlap};
    position: relative;

    img {
        max-height: 70px;
    }
`;

const MobileMenu = styled.div`
    display: flex;
    align-items: center;
`;

const MenuIcon = styled(Menu)<MenuNavStylingProp>`
    position: absolute;
    right: 0;
    color: ${(props) => props.theme.colors.white};
    width: 32px;
    cursor: pointer;
    opacity: ${({ nav }) => (nav ? '0' : '1')};
    visibility: ${({ nav }) => (nav ? 'hidden' : 'visible')};
    transform: ${({ nav }) =>
        nav ? 'rotate(180deg) scale(0)' : 'rotate(0) scale(1)'};
    transition: all 0.6s;
`;

const MenuIconClose = styled(Cross)<MenuNavStylingProp>`
    position: absolute;
    right: 0;
    color: ${(props) => props.theme.colors.white};
    width: 32px;
    cursor: pointer;
    opacity: ${({ nav }) => (!nav ? '0' : '1')};
    visibility: ${({ nav }) => (!nav ? 'hidden' : 'visible')};
    transform: ${({ nav }) =>
        !nav ? 'rotate(180deg) scale(0)' : 'rotate(0) scale(1)'};
    transition: all 0.6s;
`;

const MenuLinksMobile = styled.nav<MenuNavStylingProp>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    box-shadow: 0px 10px 14px -10px rgba(0, 0, 0, 0.5);
    max-height: ${({ nav }) => (nav ? '320px' : '0')};
    overflow: hidden;
    transition: max-height 0.8s ease;

    ul {
        list-style-type: none;
        width: 100%;
    }

    a {
        display: flex;
        justify-content: center;
        width: 100%;
        padding-top: ${(props) => props.theme.padding.regularLarge.mobile};
        padding-bottom: ${(props) => props.theme.padding.regularLarge.mobile};
        text-decoration: none;
        color: ${(props) => props.theme.colors.white};
        font-size: 16px;
    }
    a.SM-link {
        padding: 0 8px 8px 0;
    }
    a.SM-link:first-child {
        justify-content: flex-end;
    }
    a.SM-link:last-child {
        justify-content: flex-start;
    }

    .is-current {
        font-weight: ${(props) => props.theme.fontWeights.bold};
    }
`;

const MenuLinksDesktop = styled.nav`
    ul {
        display: flex;
        align-items: center;
    }
    li {
        margin: 0 16px;
        list-style-type: none;
    }
    a {
        color: ${(props) => props.theme.colors.white};
        text-decoration: none;
        font-size: ${(props) => props.theme.fontSizes.medium};
        line-height: ${(props) => props.theme.lineHeight.medium};
    }

    .is-current {
        font-weight: ${(props) => props.theme.fontWeights.bold};
    }
`;

export default Layout;
