import React, { FC } from "react";
import styled from 'styled-components';

interface ContainerProps {
    id ?: string;
}

const Container: FC<ContainerProps> = ({children, id}) => {
    return(
        <ContainerStyling>
            <ScrollPosition id={id}></ScrollPosition>
            {children}
        </ContainerStyling>
    )
}

const ContainerStyling = styled.div`
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-right: 16px;
    padding-left: 16px;

    @media screen and (min-width: ${props => props.theme.devices.mobile}) {
        width: 540px;
        max-width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }
    @media screen and (min-width: ${props => props.theme.devices.tablet}) {
        width: 720px;
        max-width: 100%;
    }
    @media screen and (min-width: ${props => props.theme.devices.laptop}) {
        width: 960px;
        max-width: 100%;
    }
    @media screen and (min-width: ${props => props.theme.devices.desktop}) {
        width: 1170px;
        max-width: 100%;
    }
    @media screen and (min-width: ${props => props.theme.devices.bigScreen}) {
        width: 1407px;
        max-width: 100%;
    }
`

const ScrollPosition = styled.div`
    position:absolute;
    top:-70px;
    height:calc(100% + 70px);
`

export default Container;